import { AssetRepository } from '@tcc-mono/classifact/shared/data-access/state';
import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { Asset } from '@tcc-mono/classifact/shared/data-access/models';

@Pipe({
  name: 'getStateVideo'
})
export class GetVideoFromStatePipe implements PipeTransform {

  constructor(
    private _assetRepo: AssetRepository,
    private _domSanitizationService: DomSanitizer
  ) { }
  transform = (videoId: string) => {
    return this._assetRepo.selectAsset(videoId)
      .pipe(
        map(url => url ? this._domSanitizationService.bypassSecurityTrustUrl(url) : '')
      );
  }
  // transform = (asset: Asset) => {
  //   return this._assetRepo._preloadAsset(asset)
  //     .pipe(
  //       map(blob => {
  //         if (blob) {
  //           // Create an Object URL from the Blob
  //           const url = URL.createObjectURL(blob);
  //           // Sanitize the URL
  //           return this._domSanitizationService.bypassSecurityTrustResourceUrl(url);
  //         }
  //         return '';
  //       })
  //     );
  // }
}
