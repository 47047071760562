import { withPagination, selectCurrentPageEntities, deleteAllPages, updatePaginationData, setPage, selectPaginationData } from '@ngneat/elf-pagination';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { selectManyByPredicate, withEntities, hasEntity, selectEntity, upsertEntities, deleteEntities, getEntitiesCount } from '@ngneat/elf-entities';
import { Asset } from '@tcc-mono/classifact/shared/data-access/models';
import { AssetService } from '@tcc-mono/classifact/shared/data-access/classifact-api';
import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { tap, take, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ElfUtil } from '@tcc-mono/shared/utils';

class AssetWithUrl {
  id: string;
  src: string;
}

const assetStore = createStore(
  { name: 'assets' },
  withEntities<Asset>(),
  withPagination()
);

const assetWithUrlStore = createStore(
  { name: 'assetWithUrl' },
  withEntities<AssetWithUrl>()
);

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AssetRepository {

  private _collectionId = new BehaviorSubject<string>('');
  private _collectionId$ = this._collectionId.asObservable();


  public assets$ = assetStore.pipe(selectCurrentPageEntities());
  public pagination$ = assetStore.pipe(selectPaginationData());


  constructor(
    private _assetService: AssetService
  ) {
    assetStore.pipe(
      selectManyByPredicate((asset: Asset) => {
        if (assetWithUrlStore.query(hasEntity(asset.id))) {
          return false;
        }
        if (asset.asset_type === 'video') {
          return true;
        }
        if (asset.asset_type !== 'image') {
          return false;
        }
        return true;
      }),
      tap(assets => {
        assets.forEach(asset => {
          this._preloadAsset(asset)
            .pipe(
              take(1)
            )
            .subscribe();
        });
      }),
      untilDestroyed(this)
    )
      .subscribe();


    // when collectionId changes, remove all existing pages
    this._collectionId$
      .pipe(
        distinctUntilChanged(),
        tap(() => assetStore.update(deleteAllPages())),
        untilDestroyed(this)
      )
      .subscribe();

  }

  public addAssets = (page: number = 1) => {
    return this._collectionId$
      .pipe(
        take(1),
        filter(collectionId => !!collectionId),
        switchMap(collection_id => this._assetService.getAll({ page, collection_id })),
        tap(({ data, meta }) => {
          const pagination = ElfUtil.convertMetaToElfPagination(meta);

          assetStore.update(
            upsertEntities(data),
            updatePaginationData(pagination),
            setPage(
              page,
              data.map(c => c.id)
            )
          )
        })
      )
      .subscribe();
  }

  setCollectionId = (collectionId: string) => {
    this._collectionId.next(collectionId);
  }

  populateAssetsFromTasks = (assets: Asset[]) => {
    assetStore.update(
      upsertEntities(assets)
    );
  }

  _preloadAsset = (asset: Asset) => {
    return this._assetService.download(asset.id)
      .pipe(
        tap((blob: Blob) => {
          const url = URL.createObjectURL(blob);
          assetWithUrlStore.update(upsertEntities({ id: asset.id, src: url }));
        }),
      )
  }

  selectAsset = (id: string) => {
    return assetWithUrlStore.pipe(selectEntity(id, { pluck: 'src' }));
  }

  removeAssetFromMemory = (assetId: string) => {
    if (assetStore.query(hasEntity(assetId))) {
      assetStore.update(
        deleteEntities(assetId)
      );
    }

    if (assetWithUrlStore.query(hasEntity(assetId))) {
      assetWithUrlStore.update(
        deleteEntities(assetId)
      );
    }
  }
}
